import Hero1 from "../images/enterprise/enterprise-hero-1.svg"
import Hero2 from "../images/enterprise/enterprise-hero-2.svg"
import Layout from "../components/layout"
import Particles from "../components/particles"
// import Phone from "../images/iphone-hero.svg"
import React from "react"
import ReduceCost1 from "../images/enterprise/reduce-cost-bg.svg"
import Reliability1 from "../images/enterprise/image--reliability-1.svg"
import Reliability2 from "../images/enterprise/image--reliability-2.svg"
import Reliability3 from "../images/enterprise/image--reliability-3.svg"
import Reliability4 from "../images/enterprise/image--reliability-4.svg"
import Seo from "../components/seo"
// import Scalability1 from "../images/enterprise/scale-1.svg"
// import Scalability2 from "../images/enterprise/scale-2.svg"
// import Scalability3 from "../images/enterprise/scale-3.svg"
import Scalability4 from "../images/enterprise/scale-4.svg"
import ScalabilityPerson from "../images/enterprise/scale-person.svg"
import Security1 from "../images/enterprise/security-image-1.svg"
import Security2 from "../images/enterprise/security-image-2.svg"
import Security3 from "../images/enterprise/security-image-3.svg"
// import appPhoneBg from "../images/app-phone-bg.svg"
// import bell from "../images/icons/bell.svg"
// import bubble1 from "../images/person-bubble-1.svg"
// import bubble2 from "../images/person-bubble-2.svg"
// import circlebg from "../images/circle-bg.svg"
// import circledotsbg from "../images/blob-bg-dots.svg"
import dollar from "../images/enterprise/dollar.svg"
import helpImg1 from "../images/enterprise/help-img-1.svg"
import helpImg2 from "../images/enterprise/help-img-2.svg"
// import laptop from "../images/laptop-global.svg"
import laptopImg1 from "../images/enterprise/laptop-img-1.svg"
import laptopImg2 from "../images/enterprise/laptop-img-2.svg"
import laptopImg3 from "../images/enterprise/laptop-img-3.svg"
// import moon from "../images/moon-stars.svg"
import { motion } from "framer-motion"
// import override from "../images/floating-ui.svg"
// import people from "../images/icons/people.svg"
// import peopleavailable from "../images/people-available.svg"
// import phoneFocused from "../images/phone-focused.png"
import psBG from "../images/home/ps-bg.svg"
// import rays from "../images/phone-rays.svg"
// import rectbg from "../images/rectangle-bg.svg"
// import schedule from "../images/schedule-ui.png"
// import section3phone from "../images/iphone-incidents.png"
import statusDots from "../images/status-dots.svg"
// import uiIncident from "../images/ui-incident.svg"
import OGEnterprise from "../images/og-enterprise.png"

const EnterprisePage = () => (
  <div className="dark-layout">
    <Layout className="enterprise">
      <Seo
        title="Ready Five Enterprise · Fully private incident management software"
        description="Ready Five incident management software gives your team the tools to notify the right people so you can quickly restore service."
        ogImage={OGEnterprise}
      />
      <div>
        <section className="dark-hero-container">
          <div className="container">
            <div className="hero--section__dark with-top-padding">
              <div
                data-sal="slide-right"
                data-sal-easing="ease"
                data-sal-delay="400"
                data-sal-duration="1000"
                className="content"
              >
                <h1 className="main-title">ENTERPRISE</h1>
                <p
                  style={{
                    marginBottom: "1em",
                    fontSize: "21px",
                    lineHeight: "32px",
                    maxWidth: "400px",
                  }}
                >
                  Privacy focused incident management and response for complete
                  peace of mind. Fully serverless and hosted in your AWS
                  account.
                </p>
                <motion.div
                  whileHover={{ scale: 0.98 }}
                  whileTap={{ scale: 1 }}
                  className="mt-2 btn--container"
                >
                  <a
                    className="btn btn--primary"
                    href="https://app.readyfive.io/signup"
                  >
                    Try it free for 14 days
                  </a>
                </motion.div>
              </div>

              <div className="image-container--hero hero__dark">
                <img
                  alt="Hero 1"
                  data-sal="slide-left"
                  data-sal-delay="500"
                  data-sal-easing="ease"
                  data-sal-duration="700"
                  src={Hero1}
                />
                <img
                  alt="Hero 2"
                  data-sal="slide-left"
                  data-sal-delay="500"
                  data-sal-easing="ease"
                  data-sal-duration="700"
                  className="lower--right"
                  src={Hero2}
                />
                <img
                  data-sal="slide-left"
                  data-sal-delay="500"
                  data-sal-easing="ease"
                  data-sal-duration="700"
                  src={statusDots}
                  alt="status types"
                  className="status-dots"
                />
              </div>
            </div>
          </div>
          <Particles />
        </section>

        <section className="section--lte">
          <div className="container container--reverse">
            <div className="section--header" style={{ marginTop: "3em" }}>
              <h2>Security on your terms</h2>
              <h4>Installs to your own AWS account, bound by your policies.</h4>
              <p>
                Keeps your private incident data under your control. All data is
                encrypted at rest and stored in your account. You own it
                forever, and only you can access it.
              </p>
              <div className="image-container image-container--ps">
                <img
                  data-sal="slide-up"
                  data-sal-easing="ease"
                  data-sal-duration="1000"
                  src={Security1}
                  alt="iPhone"
                  className="lower--right"
                  style={{ maxWidth: "250px" }}
                />
                <img
                  data-sal="slide-up"
                  data-sal-easing="ease"
                  data-sal-duration="1000"
                  src={Security2}
                  alt="Web Layout"
                  className="ps-window"
                />
                <img
                  data-sal="slide-up"
                  data-sal-easing="ease"
                  data-sal-duration="1000"
                  src={Security3}
                  alt="lock"
                  className="lower--left"
                  style={{ maxWidth: "200px" }}
                />
                <img
                  data-sal="slide-up"
                  data-sal-easing="ease"
                  data-sal-duration="1000"
                  src={psBG}
                  alt="background"
                  className="ps-bg back"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="section--gray">
          <div
            className="section--header section--header_left"
            style={{ maxWidth: "1000px", marginTop: "0px" }}
          >
            <h2
              style={{
                fontSize: "32px",
                paddingTop: "2em",
                maxWidth: "500px",
              }}
            >
              Exceeds reliability and availability requirements
            </h2>
          </div>
          <div className="container" style={{ maxWidth: "1000px" }}>
            <div className="grid-d-f d-f-row-r">
              <div className="image-container image-container--sm">
                <img
                  data-sal="slide-left"
                  data-sal-easing="ease"
                  data-sal-duration="500"
                  className="front"
                  src={Reliability1}
                  alt="Reliability 1"
                />
              </div>
              <div className="section--content section--content__left">
                <p>Deploy to AWS regions around the world.</p>
                <p style={{ color: "gray" }}>
                  All features are supported in US West (Oregon), US East (N.
                  Virginia), and EU West (Ireland). 8 additional regions are
                  supported, but do not support inbound email-based
                  integrations.{" "}
                </p>
                <a
                  className="link-small"
                  href="https://www.readyfive.io/docs/enterprise/installation#choose-a-region"
                >
                  Learn more
                </a>
              </div>
            </div>
            <div className="grid-d-f">
              <div className="image-container image-container--sm">
                <img
                  data-sal="slide-right"
                  data-sal-easing="ease"
                  data-sal-duration="500"
                  className="front"
                  src={Reliability2}
                  alt="Reliability 2"
                />
              </div>
              <div className="section--content section--content__left">
                <p>
                  Configure in a region isolated from your primary
                  infrastructure.
                </p>
                <p style={{ color: "gray" }}>
                  With many regions to choose from, you can install to the ideal
                  location for your availability and latency requirements. It's
                  a single-tenant installation (just you), so there are no noisy
                  neighbors.
                </p>
                <a
                  className="link-small"
                  href="https://www.readyfive.io/docs/enterprise/installation#choose-a-region"
                >
                  Learn more
                </a>
              </div>
            </div>
            <div className="grid-d-f d-f-row-r">
              <div className="image-container image-container--sm">
                <img
                  data-sal="slide-left"
                  data-sal-easing="ease"
                  data-sal-duration="500"
                  className="front"
                  src={Reliability3}
                  alt="Reliability 3"
                />
              </div>
              <div className="section--content section--content__left">
                <p>
                  Runs entirely on AWS managed, "serverless", multi-datacenter,
                  pay-as-you-go, auto-scaling services.
                </p>
                <p style={{ color: "gray" }}>
                  Operate with confidence knowing that Ready Five always runs
                  100% managed services that span multiple availability zones
                  within an AWS region.
                </p>
                <a
                  className="link-small"
                  href="https://www.readyfive.io/docs/enterprise/installation#choose-a-region"
                >
                  Learn more
                </a>
              </div>
            </div>
            <div className="grid-d-f">
              <div className="image-container image-container--sm">
                <img
                  data-sal="slide-right"
                  data-sal-easing="ease"
                  data-sal-duration="500"
                  className="front"
                  src={Reliability4}
                  alt="Reliability 4"
                />
              </div>
              <div className="section--content section--content__left">
                <p>
                  Fully managed by AWS with zero maintenance and automatic
                  updates.
                </p>
                <p style={{ color: "gray" }}>
                  AWS platform issues are self-healing and Ready Five software
                  automatically stays up-to-date with no maintenance required
                  from your staff.
                </p>
                <a
                  className="link-small"
                  href="https://www.readyfive.io/docs/enterprise/installation#choose-a-region"
                >
                  Learn more
                </a>
              </div>
            </div>
          </div>
        </section>
        <section
          className="section--white d-f fd-c"
          style={{ padding: "4em 0 0 0" }}
        >
          <div className="container">
            <div className="section--header" style={{ marginTop: "4em" }}>
              <h2>Built for scale</h2>
              <h4>Send a firehose of data, no problem.</h4>
              <p>
                Consumer and enterprise applications emit a constantly growing
                amount of data and metrics. Data ingestion is backed by AWS
                services capable of absorbing thousands of requests per second
                without breaking a sweat.
              </p>
            </div>
            <div className="image-container image-container--scalabilty">
              <img
                data-sal="zoom-in"
                data-sal-delay="200"
                data-sal-easing="ease"
                data-sal-duration="1000"
                className="sp sp-1"
                src={ScalabilityPerson}
                alt="square person 1"
              />
              <img
                data-sal="zoom-in"
                data-sal-delay="300"
                data-sal-easing="ease"
                data-sal-duration="1000"
                className="sp sp-2"
                src={ScalabilityPerson}
                alt="square person 2"
              />
              <img
                data-sal="zoom-in"
                data-sal-delay="500"
                data-sal-easing="ease"
                data-sal-duration="1000"
                className="sp sp-3"
                src={ScalabilityPerson}
                alt="square person 3"
              />
              <img
                data-sal="zoom-in"
                data-sal-delay="700"
                data-sal-easing="ease"
                data-sal-duration="1000"
                className="sp sp-4"
                src={ScalabilityPerson}
                alt="square person 4"
              />
              <img
                data-sal="zoom-in"
                data-sal-delay="100"
                data-sal-easing="ease"
                data-sal-duration="1000"
                className="mt-2"
                src={Scalability4}
                alt="squares grid"
              />
            </div>
          </div>
        </section>
        <section className="section--lte">
          <div className="container grid grid-2">
            <div className="section--content">
              <h2>Reduce your costs, really.</h2>
              <h4 style={{ lineHeight: "24px", marginBottom: "24px" }}>
                All AWS services used have pay-as-you-go pricing, so you're
                never paying for idle systems.
              </h4>
              <p>
                No robbing Peter to pay Paul here. Many self-hosted services
                reduce costs by putting a maintenance burden on your staff. Here
                there is no maintenance required, there are no EC2 servers to
                maintain and patch, and there are no scaling knobs to turn. Set
                it and forget it.
              </p>
            </div>
            <div className="image-container image-container--reduce">
              <div className="card-purple">
                <div className="card-purple--inner">
                  <img
                    data-sal="zoom-in"
                    data-sal-delay="400"
                    data-sal-easing="ease"
                    data-sal-duration="1000"
                    className="card-image"
                    src={dollar}
                    alt="Dollar"
                  />
                  <h1>$18</h1>
                  <h5>per month | per user</h5>
                </div>
                <div className="card-purple--footer">
                  <p>Low per user licensing</p>
                </div>
              </div>
              <img
                data-sal="zoom-in"
                data-sal-delay="400"
                data-sal-easing="ease"
                data-sal-duration="1000"
                style={{ width: "100%" }}
                src={ReduceCost1}
                alt="Reduce Cost Background"
                className="bg-circles"
              />
            </div>
          </div>
          <div className="container grid grid-2" style={{ marginTop: "6em" }}>
            <div className="image-container image-container--setup">
              <img
                data-sal="zoom-in"
                data-sal-delay="700"
                data-sal-easing="ease"
                data-sal-duration="1000"
                src={laptopImg1}
                className="laptopImg1"
                alt="Laptop 1"
              />
              <img
                data-sal="zoom-in"
                data-sal-easing="ease"
                data-sal-duration="1000"
                data-sal-delay="800"
                src={laptopImg2}
                className="laptopImg2"
                alt="Laptop 2"
              />
              <img
                data-sal="zoom-in"
                data-sal-easing="ease"
                data-sal-delay="1000"
                data-sal-duration="1000"
                src={laptopImg3}
                className="laptopImg3"
                alt="Laptop 3"
              />
            </div>
            <div className="section--content">
              <h2>Fast Setup</h2>
              <h4 style={{ lineHeight: "24px", marginBottom: "24px" }}>
                Get up and running in less than 30 minutes
              </h4>
              <p style={{ margin: "0.5em 0" }}>
                There is some brief AWS account preparation required, then the
                installation is fully automated using AWS CloudFormation.{" "}
                <a href="https://www.readyfive.io/docs/enterprise/installation">
                  Learn more
                </a>
              </p>
              <p style={{ margin: "0.5em 0" }}>
                Ready Five Enterprise runs on AWS services such as Lambda,
                DynamoDB, AppSync, API Gateway, and others that are configured
                in just a few minutes for use at scale using
                infrastructure-as-code.
              </p>
              <p style={{ margin: "0.5em 0" }}>
                All your data is automatically encrypted at rest and has backups
                configured for point-in-time recovery to meet the most strict
                durability requirements.
              </p>
            </div>
          </div>
        </section>
        <section className="section--color">
          <div className="container grid grid-2">
            <div className="section--content">
              <h2>Need help deciding or have questions?</h2>
              <p>
                Our documentation has all the details for getting up and running
                or you can contact us with questions. We promise a quick reply!
              </p>
              <motion.div
                whileHover={{ scale: 0.98 }}
                whileTap={{ scale: 1 }}
                className="mt-2 btn--list"
              >
                <a
                  className="btn btn--primary btn--small btn--outline"
                  href="mailto:support@readyfive.io"
                >
                  Email Us
                </a>
              </motion.div>
              <motion.div
                whileHover={{ scale: 0.98 }}
                whileTap={{ scale: 1 }}
                className="mt-2 btn--list"
              >
                <a
                  className="btn btn--primary btn--small btn--outline"
                  href="https://twitter.com/getreadyfive"
                >
                  Ask us on Twitter
                </a>
              </motion.div>
              <motion.div
                whileHover={{ scale: 0.98 }}
                whileTap={{ scale: 1 }}
                className="mt-2 btn--list"
              >
                <a
                  className="btn btn--primary btn--small btn--outline"
                  href="https://www.readyfive.io/docs/enterprise-basics"
                >
                  Read The Installation Docs
                </a>
              </motion.div>
            </div>
            <div className="image-container image-container--contact">
              <img
                data-sal="zoom-in"
                data-sal-delay="700"
                data-sal-easing="ease"
                data-sal-duration="1000"
                src={helpImg1}
                className="contact-img-1 mobile-invis"
                alt="App Phone"
              />
              <img
                data-sal="slide-left"
                data-sal-easing="ease"
                data-sal-duration="1000"
                src={helpImg2}
                alt="App Phone 2"
                className="mobile-invis"
              />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  </div>
)

export default EnterprisePage
